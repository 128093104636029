//general
import React from "react"
//styles
import * as styles from "./styles.module.scss"

function NotFound() {
  return (
    <section className={styles.notFound}>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>
  )
}

export default NotFound
