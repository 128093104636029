//general
import * as React from "react"
//components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import NotFound from "../templates/NotFound"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <NotFound />
  </Layout>
)

export default NotFoundPage
